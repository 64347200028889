import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import "hammerjs";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgxLocalStorageModule } from "ngx-localstorage";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LeftnavComponent } from "./layout/leftnav/leftnav.component";
import { ModuleModule } from "./sharedModule/materials.module";
import { CommonModule } from "@angular/common";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { RequestInterceptor } from "./interceptor/RequestInterceptor";
import { NgModulesModule } from "./sharedModule/ng-modules.module";
import { McBreadcrumbsModule } from "ngx-breadcrumbs-ui";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTreeModule } from "@angular/material/tree";
import { NgxChartsModule } from "@swimlane/ngx-charts";

@NgModule({
  declarations: [AppComponent, LeftnavComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxLocalStorageModule.forRoot(),
    BrowserAnimationsModule,
    ModuleModule,
    AngularFontAwesomeModule,
    NgModulesModule,
    McBreadcrumbsModule.forRoot(),
    NgxSpinnerModule,
    MatIconModule,
    MatSidenavModule,
    MatTreeModule,
    NgxChartsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
