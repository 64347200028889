import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loader = new Subject<boolean>()
  constructor(public spinner: NgxSpinnerService) { 
  }

  showLoader() : void {
    this.spinner.show();
  }

  hideLoader() : void {
    this.spinner.hide();
  }
}
