import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VideotimelinePipe} from '../core/pipe/videotimeline.pipe'
import {SortNumberPipe} from '../core/pipe/sort-number.pipe'
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { ColorSketchModule } from 'ngx-color/sketch';
import { FileUploadModule } from 'ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';
import { DndModule } from 'ngx-drag-drop';
import { ReadMoreDirective } from './readMoreDirective';

@NgModule({
  declarations: [
    SortNumberPipe,
    VideotimelinePipe,
    ReadMoreDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ColorSketchModule,
    FileUploadModule,
    ToastrModule.forRoot(),
    DndModule
  ],
  exports:[
    SortNumberPipe,
    VideotimelinePipe,
    FormsModule,
    ReactiveFormsModule,
    ColorSketchModule,
    FileUploadModule,
    ToastrModule,
    DndModule,
    ReadMoreDirective
  ],
  providers:[
  ]
})
export class NgModulesModule { }
