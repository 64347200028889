import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
@Injectable({
  providedIn: "root",
})
export class EncryptionService {
  passcode = "nicedemo";
  constructor() {}

  encode(text: string): string {
    var b64 = CryptoJS.AES.encrypt(text, "").toString();
    var e64 = CryptoJS.enc.Base64.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }

  decode(text: string): string {
    var reb64 = CryptoJS.enc.Hex.parse(text);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, "");
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  }
}
