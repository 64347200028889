import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "ngx-localstorage";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { TabService, LeftMenuTab } from "src/app/service/tab.service";
import { AuthenticationService } from "../../service/authentication.service";
import { ThemeService } from "src/app/service/theme.service";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
interface TreeNode {
  name: string;
  exName: string;
  children?: TreeNode[];
}

/** Flat node with expandable and level information */
interface LeftavFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: "app-leftnav",
  templateUrl: "./leftnav.component.html",
  styleUrls: ["./leftnav.component.scss"],
})
export class LeftnavComponent implements OnInit {
  userName: string = "";
  panelOpenState = false;
  leftMenu: Array<Menu>;
  letmenuTabActive: any;
  signRole: string = "";

  constructor(
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private tabService: TabService,
    public autService: AuthenticationService,
    private themeService: ThemeService
  ) {}

  getTreeNodeDataByRole(role) {
    if (role === null) return [];
    //let role = this.autService.getUserInfo()["role"];
    if (role == 4) {
      return [
        {
          name: "CHANNELS",
          exName: "courses",
        },
        {
          name: "CAMPAIGN",
          exName: "",
        },
        {
          name: "ATTRIBUTES",
          exName: "author,Topics,Lenses,pricing,video",
          children: [
            {
              name: "Author",
              exName: "",
            },
            {
              name: "Topics",
              exName: "",
            },
            {
              name: "Lenses",
              exName: "",
            },
            {
              name: "Pricing",
              exName: "",
            },
            {
              name: "Video list",
              exName: "Video",
            },
            {
              name: "Home page header",
              exName: "Home-page-header",
            },
          ],
        },
        {
          name: "ANALYTICS",
          exName: "",
        },
        {
          name: "WORKSHOP ASSIGN",
          exName: "wrkshpAssgn",
        },
        {
          name: "NOTIFICATION",
          exName: "sentNtf",
        },
      ];
    } else {
      return [
        {
          name: "CHANNELS",
          exName: "courses",
        },
      ];
    }
  }
  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      exName: node.exName,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<LeftavFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: LeftavFlatNode) => node.expandable;

  ngOnInit() {
    this.autService.authObser.subscribe((authInfo: any) => {
      if (authInfo != null) {
        this.userName = authInfo["user_name"];
        this.dataSource.data = this.getTreeNodeDataByRole(authInfo["role"]);
      } else this.dataSource.data = [];
    });
  }

  redirectToScreen(nodeName: string) {
    //courses

    if (nodeName.toLowerCase().includes("channels"))
      this.router.navigateByUrl("/channels");
    else if (nodeName.toLowerCase().includes("campaign"))
      this.router.navigateByUrl("/campaign");
    else if (nodeName.toLowerCase().includes("author"))
      this.router.navigateByUrl("/author");
    else if (nodeName.toLowerCase().includes("pricing"))
      this.router.navigateByUrl("/pricing");
    else if (nodeName.toLowerCase().includes("video list"))
      this.router.navigateByUrl("/channels/VideoList");
    else if (nodeName.toLowerCase().includes("lenses"))
      this.router.navigateByUrl("/lenses");
    if (nodeName.toLowerCase().includes("topics"))
      this.router.navigateByUrl("/topics");
    if (nodeName.toLowerCase().includes("analytics"))
      this.router.navigateByUrl("/analytics");
    if (nodeName.toLowerCase().includes("workshop"))
      this.router.navigateByUrl("/wrkshpAssgn");
    if (nodeName.toLowerCase().includes("notification"))
      this.router.navigateByUrl("/sentNtf");

    if (nodeName.includes("Home page header"))
      this.router.navigateByUrl("/channels/Home-page-header");
    //wrkshpAssgn
    console.log(nodeName.includes("Home page header"));
  }

  /**
   * get user info
   */
  getUserInfo(): void {
    this.userName = this.autService.getUserInfo()["user_name"];
  }
  /**
   *
   */
  signOut(): void {
    this.autService.logOut();
  }

  isActiveUrl(node: TreeNode): any {
    let url = window.location.href;
    url = url.toLowerCase();
    if (url.includes(node.name.trim().toLowerCase())) return "activated";
    else if (
      url.includes(node.exName.trim().toLowerCase()) &&
      node.exName != ""
    )
      return "activated";
    else if (node.exName.includes(",")) {
      return node.exName
        .split(",")
        .find((exName) => url.toLowerCase().includes(exName.toLowerCase()))
        ? "activated"
        : "";
    } else return "inactive";
  }
}

export class Menu {
  title: string;
  url: string;
  role: string;
}
