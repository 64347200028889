import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthguardGuard } from "./guard/authguard.guard";

const routes: Routes = [
  {
    path: "public",
    loadChildren: () =>
      import("./module/public/public.module").then((m) => m.PublicModule),
  },
  {
    path: "channels",
    data: {
      // Uses static text (Home)
      breadcrumbs: "channels",
    },
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/channels/channels.module").then(
        (m) => m.ChannelsModule
      ),
  },
  {
    path: "courses/:channelId",
    loadChildren: () =>
      import("./module/private/courses/courses.module").then(
        (m) => m.CoursesModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "courses/:channelId/:tabIndex",
    loadChildren: () =>
      import("./module/private/courses/courses.module").then(
        (m) => m.CoursesModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "categories/:channelId",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/categories/categories.module").then(
        (m) => m.CategoriesModule
      ),
  },
  {
    path: "members/:channelId",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/members/members.module").then(
        (m) => m.MembersModule
      ),
  },

  {
    path: "author",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/autherprofile/autherprofile.module").then(
        (m) => m.AutherprofileModule
      ),
  },
  {
    path: "campaign",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/campaign/campaign.module").then(
        (m) => m.CampaignModule
      ),
  },
  {
    path: "pricing",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/price-tier/price-tier.module").then(
        (m) => m.PriceTierModule
      ),
  },

  {
    path: "package/:channelID",
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./module/private/package/coursepackage.module").then(
        (m) => m.CoursepackageModule
      ),
  },
  {
    path: "lenses",
    loadChildren: () =>
      import("./module/private/lenses/lenses.module").then(
        (m) => m.LensesModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "topics",
    loadChildren: () =>
      import("./module/private/topics/topics.module").then(
        (m) => m.TopicsModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "podcast/:channelID",
    loadChildren: () =>
      import("./module/private/podcast/podcast.module").then(
        (m) => m.PodcastModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "analytics",
    loadChildren: () =>
      import("./module/private/analytics/analytics.module").then(
        (m) => m.AnalyticsModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "wrkshpAssgn",
    loadChildren: () =>
      import("./module/private/workshopassign/workshopassign.module").then(
        (m) => m.WorkShopAssignModule
      ),
    canActivate: [AuthguardGuard],
  },
  {
    path: "sentNtf",
    loadChildren: () =>
      import("./module/private/sendNotification/sendNotification.module").then(
        (m) => m.SendNotification
      ),
    canActivate: [AuthguardGuard],
  },

  {
    path: "",
    redirectTo: "public",
    pathMatch: "full",
  },
  // { path: '**', redirectTo: '/channels/channel-list/list' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
