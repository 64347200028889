import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { EncryptionService } from './encryption.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
private url:string = environment.baseUrl

public authSubject = new BehaviorSubject(null);

public authObser:Observable<any> = this.authSubject.asObservable();

constructor(
    private _http:HttpClient,
    private localStorage:LocalStorageService,
    private router:Router,
    private cryptoService:EncryptionService
  ) { 
    this.authSubject.next(this.getUserInfo())
  }

  /**
   *
   * @param body {body with user email and password body{email:"",password:""}}
   */
  authenthWithEmailAndPassword(body:AuthBody) : Observable<any> {
    return  this._http.post(this.url+"/ProductRESTService.svc/LoginAdminPanel",body);
  }

  registerNewAdmin(body:AdminNew) : Observable<any> {
      return this._http.post(this.url+"/ProductRESTService.svc/RegisterUser",body);
  }

  /**
   * is user is logged in
   */
  isLoggedIn() : boolean {
      return this.localStorage.get("admin-info") != null;
  }
  /**
   * get user info
   */
  getUserInfo():LogedUser{
    let user = new LogedUser();
    if(this.localStorage.get("admin-info") != null){
       user =  JSON.parse(this.cryptoService.decode(this.localStorage.get("admin-info")));
    }
    return user;
  }
  /**
   * Log out
   */
  logOut() : void {
    this.authSubject.next(null);
    this.localStorage.clear();
    this.router.navigateByUrl("/");
  }
  getUserId() : number {
    let local = this.localStorage.get("admin-info") ;
    let userInfo  = JSON.parse(this.cryptoService.decode(local))
    if(local != null) return userInfo['ID'];
    else return null;
  }
  isSessionExpired() :Boolean{
    return sessionStorage.getItem("admin-info")!=null;
  }
}



/**
 * class of auth wth email and password
 */

 export class AuthBody {
   email:string;
   password:string;
 }

 /**
  * new user body
  */

  export class AdminNew{
    firstname:string;
    middlename:string;
    lastname:string;
    email:string;
    password:string;
    confirmPassword:string;
    usertypeid:string;
    RegFrmType :string;
  }

  export class LogedUser{
    ID: number;
RegFrmType: number;
TokenValue: string;
createdby: number;
email:string;
firstname: string;
isactive: number;
lastname: string;
message: string;
middlename: string;
name: string;
password: string;
usertypeid: number;
  }
