import { Component, ElementRef, ViewChild } from '@angular/core';
import { LoaderService } from './service/loader.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-localstorage';
import { ThemeService } from './service/theme.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from './service/authentication.service';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'video-admin';
  loaderState:boolean = false;
  isDarkTheme: Observable<boolean>;

  isUserLoggedIn:boolean = false;
 @ViewChild('pRef', {static: false}) pRef: ElementRef;

  constructor(
    private loader:LoaderService,
    private http:HttpClient,
    private local:LocalStorageService,
    public themeService:ThemeService,
    public logInService:AuthenticationService,
    private router:Router
  ){
    this.loader.loader.subscribe(stat=>{
      this.loaderState = stat;
    })
   
    this.isDarkTheme = this.themeService.isDarkTheme;

    
    this.router.events.subscribe((event)=>{
      if(this.logInService.isLoggedIn()) this.isUserLoggedIn=true;
      else this.isUserLoggedIn = false;
    })
  }

}
