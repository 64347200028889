import { Component, OnInit, Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from 'ngx-localstorage';
import { AuthenticationService } from "../service/authentication.service";

@Injectable({
  providedIn: "root"
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private localStorage: LocalStorageService,
    private authService:AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.isLoggedIn()) {
      return next.handle(request);
    } else {
      request = request.clone({
        setHeaders: {
          Token: this.authService.getUserInfo()['token']
        }
      });
      return next.handle(request);
    }
  }
}
