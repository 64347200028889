import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TabService {
  leftmenuTabActive: LeftMenuTab;
  userRole: string = "Public";
  constructor() {
    let tab = new LeftMenuTab("");
    this.leftmenuTabActive = tab;
  }

  setTabActive(tab: LeftMenuTab): void {
    this.leftmenuTabActive = tab;
  }
  getTabActive(): LeftMenuTab {
    return this.leftmenuTabActive;
  }
  setUserRole(role: string): void {
    this.userRole;
  }
  getUserRole(): string {
    return this.userRole;
  }
}

export class LeftMenuTab {
  title: string = "";
  id: number;
  constructor(tile:string) {
    this.title = tile;
    this.id = 0;
  }
}
