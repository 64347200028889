import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "videotimeline"
})
export class VideotimelinePipe implements PipeTransform {
  transform(duration: any, ...args: any[]): any {
    let duratin = parseInt(duration);
    let seconds = ~~(duration / 60);

    return seconds;
  }
}
